<script>
import axios from 'axios';
// // import Switches from "vue-switches";
// import Swal from 'sweetalert2';
import Swal from 'sweetalert2';
import moment from 'moment';
import _ from "lodash";

export default {
  props: [ ],
  components: {
  },
  mounted () {

  },
  data() {
    return {
      data: {
        status: '',
        ticketId: '',
        ticketNo: 'memuat data...',
        stage: []
      },
      
      listStatus: [
        {
          text: 'Open',
          value: 'open'
        },
        {
          text: 'Closed',
          value: 'closed'
        }
      ],
      choice: {
        status: {
          text: 'Pilih',
          value: ''
        }
      }
    }
  },
  methods: { 
    show (id) {
      // console.log('idnya', id)
      this.getData(id)
      this.$refs.modal.show();
    },
    async getData (id) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket?ticketId=' + id,).then(async response => {
        this.data = response.data
        this.data.stage = _.orderBy(response.data.stage, ['createdAt'], ['asc'])
      });
    },
    resetModal () {
      this.data = {
        status: '',
        ticketId: '',
        ticketNo: '',
        stage: []
      }
    },
    async patchData () {
      let obj = {
        status: this.data.status
      }
      if (this.data.status=='closed') {
        const stageArr = {
          code: 'ticketDone',
          createdAt: moment().format(),
          createdBy: this.$store.state.authfack.user.username
        }
        // stageArr.push(this.dataStage)
        this.data.stage.push(stageArr)
        obj.stage = this.data.stage
      }
      // console.log('datanya', obj)
      
      await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.data.ticketId, obj)
        // eslint-disable-next-line no-unused-vars
          .then(async response => {
            // console.log('resp', response)
            this.$emit('loadOptionDataParent')
            Swal.fire("Sukses!", "Data sukses disimpan!", "success");
            this.$refs['modal'].hide()
          }).catch(error=>{
            // console.error(error)
            Swal.fire("Gagal!", error.message, "error");
          })
    }
  },
  watch: {
    'choice.status.value': {
      immediate: true,
      handler (newValue) {
        // console.log('jalan')
        if (newValue === 0 || newValue) {
          this.data.status = newValue
        }
      }
    },
    'data.status': {
      immediate: true,
      handler (newValue) {
        if (newValue === 0 || newValue) {
          const index = this.listStatus.findIndex(x => x.value === newValue)
          if (index >= 0) {
            this.choice.status.text = this.listStatus[index].text
            this.choice.status.value = newValue
          }
        }
      }
    },
  }
};
</script>
  
<template>
  <b-modal id="modal" ref="modal" size="m" centered :title="data.ticketNo" @hide="resetModal">
    <div class="row mb-2">
      Status Ticket: 
      <b-form-select
      class="mt-2"
        for="text"
        style="width: 100%; height: 40px;"
        v-model="choice.status.value"
        value-field="value"
        text-field="text"
        :options="listStatus"
        ></b-form-select>
    </div>
    <!-- <div class="row">
      <div class="col-3 mt-1">
        Signal
      </div>
      <div v-if="signal.status!='-'" :class="{
                                        'text-success col-8': signal.status == 'Very good',
                                        'text-warning col-8': signal.status == 'Warning',
                                        'text-danger col-8': signal.status == 'Critical'
                                    }">
       {{ signal.text }}
      </div>
      <div v-else class='text-danger col-8'>
       Not Connected
      </div>
    </div> -->
    <template #modal-footer>
        <b-button  variant="primary" @click="patchData()">
          Simpan
        </b-button>
      </template>
  </b-modal>
</template>
  