<script>
import axios from 'axios';
// // import Switches from "vue-switches";
// import Swal from 'sweetalert2';
import Swal from 'sweetalert2';

export default {
  props: [ ],
  components: {
  },
  mounted () {

  },
  data() {
    return {
      data: {
        orderId: '',
        status: '-',
        ipAddress: '-'
      },
      title: '',
      signal: {
        status: '-',
        receive: '-',
        transmit: '-'
      },
      loadingPon: false
    }
  },
  methods: {
    resetModal () {
      this.data = {
        orderId: '',
        status: '-',
        ipAddress: '-'
      }
      this.title = '',
      this.signal = {
        status: '-',
        receive: '-',
        transmit: '-'
      }
      this.loadingPon = false
    },  
    show (id) {
      // console.log('idnya', id)
      this.getData(id)
      this.$refs.modal.show();
    },
    async getData (id) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?productId=' + id,).then(async response => {
        this.data.orderId = response.data.orderId;
        this.title = 'Produk ' + response.data.orderId
        // console.log("sini", response.data);
        // await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?checkSignal=true&orderId=' + this.data.orderId,).then(response2 => {
        //   console.log("sini", response2.data);
        //   if(response2.data.status){
        //     this.signal.status = response2.data.onu_signal
        //     this.signal.text = response2.data.onu_signal + ', ' + response2.data.onu_signal_1490 + '/' + response2.data.onu_signal_1310
        //   }
        // });
      });
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'pppoe-service/' + this.data.orderId).then(async response => {
        // console.log('rep', response.data)
        this.data.ipAddress = response.data.address
      });
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'pppoe-service?radcheck=' + this.data.orderId).then(async response => {
       
        if (response.data[0].value=='active') {
          this.data.status = 'Aktif'
        } else {
          this.data.status = 'Isolir'
        }
      });
    },
    async getPon () {
      this.loadingPon = true
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?checkSignal=true&orderId=' + this.data.orderId,).then(response2 => {
        // console.log("sini", response2.data);
        if(response2.data.status){
          if (response2.data.onu_signal=='Very good') {
            this.signal.status = 'Baik'
          } else if (response2.data.onu_signal=='Warning') {
            this.signal.status = 'Kurang Baik'
          } else {
            this.signal.status = 'Buruk'
          }
          this.signal.receive = response2.data.onu_signal_1490
          this.signal.transmit = response2.data.onu_signal_1310
          this.loadingPon =false
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Gagal memuat data!",
            showConfirmButton: false,
            timer: 3000,
          });
          this.loadingPon = false
        }
      }).catch(error=>{
        console.log('error',error)
      });
    },
    async restartDevice () {
      const body = {
        orderId: this.data.orderId
      }
      
      // eslint-disable-next-line no-unused-vars
      await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?restart-device=true', body).then(response => {
        // console.log("sini", response.data);
        Swal.fire(
            'Sukses!',
            'Berhasil Melakukan Restart',
            'success'
        )
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
    }
  },
  watch: {
      
  }
};
</script>
  
<template>
  <b-modal id="modal" ref="modal" size="m" centered :title="title" @hide="resetModal">
    <div class="row mb-2">
      <div class="col-3 ">
        Status:
      </div>
      <div class="col-4">
        {{ data.status }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3 ">
        Alamat IP:
      </div>
      <div class="col-4">
        {{ data.ipAddress }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        PON:
      </div>
      <div class="col-4">
        <b-button size="sm" variant="primary" @click="getPon()">
          <b-spinner v-if="loadingPon" small variant="light"
        ></b-spinner>
        Cek PON
        </b-button >
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3 ">
       PON Status:
      </div>
      <div class="col-4">
       {{ signal.status }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3 ">
        Power Kirim:
      </div>
      <div class="col-4">
        {{ signal.transmit }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3">
        Power Terima:
      </div>
      <div class="col-4">
        {{ signal.receive }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-3 mt-1">
        Restart Device
      </div>
      <div class="col-4">
        <b-button size="sm" variant="primary" @click="restartDevice()">
          <i class="fas fa-redo "></i>
        </b-button >
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-3 mt-1">
        Signal
      </div>
      <div v-if="signal.status!='-'" :class="{
                                        'text-success col-8': signal.status == 'Very good',
                                        'text-warning col-8': signal.status == 'Warning',
                                        'text-danger col-8': signal.status == 'Critical'
                                    }">
       {{ signal.text }}
      </div>
      <div v-else class='text-danger col-8'>
       Not Connected
      </div>
    </div> -->
  </b-modal>
</template>
  