var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"modal","centered":"","title":"Form Tambah Produk","title-class":"font-18","size":"lg"},on:{"hide":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Simpan ")])]},proxy:true}])},[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Produk","label-for":"text"}},[_c('b-form-select',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.productData.products.$error,
                    },staticStyle:{"width":"100%","height":"40px"},attrs:{"for":"text","options":_vm.listProduct},model:{value:(_vm.choice.product),callback:function ($$v) {_vm.$set(_vm.choice, "product", $$v)},expression:"choice.product"}}),(_vm.submittedForm && _vm.$v.data.productData.products.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.productData.products.required)?_c('span',[_vm._v("Produk tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Harga","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.price.$error,
                    },attrs:{"for":"text","type":"number"},model:{value:(_vm.data.transactionData.price),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "price", $$v)},expression:"data.transactionData.price"}}),(_vm.submittedForm && _vm.$v.data.transactionData.price.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.price.required)?_c('span',[_vm._v("Harga tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Biaya Instalasi","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.cost.$error,
                    },attrs:{"for":"text","type":"number"},model:{value:(_vm.data.transactionData.cost),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "cost", $$v)},expression:"data.transactionData.cost"}}),(_vm.submittedForm && _vm.$v.data.transactionData.cost.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.cost.required)?_c('span',[_vm._v("Biaya instalasi tidak boleh kosong!.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Tanggal Registrasi","label-for":"date"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.registeredPackage.$error,
                    },attrs:{"id":"date","for":"text","type":"date","value":"2023/09/01"},model:{value:(_vm.data.transactionData.registeredPackage),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "registeredPackage", $$v)},expression:"data.transactionData.registeredPackage"}}),(_vm.submittedForm && _vm.$v.data.transactionData.registeredPackage.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.registeredPackage.required)?_c('span',[_vm._v("Tanggal registrasi tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Status","label-for":"text"}},[_c('b-form-select',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.productData.productStatus.status.$error,
                    },staticStyle:{"width":"100%","height":"40px"},attrs:{"for":"text","options":_vm.statusList},model:{value:(_vm.data.productData.productStatus.status),callback:function ($$v) {_vm.$set(_vm.data.productData.productStatus, "status", $$v)},expression:"data.productData.productStatus.status"}}),(_vm.submittedForm && _vm.$v.data.productData.productStatus.status.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.productData.productStatus.status.required)?_c('span',[_vm._v("Status tidak boleh kosong!")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Pembayaran Pertama","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.firstPayment.$error,
                    },attrs:{"for":"text","type":"number","disabled":""},model:{value:(_vm.data.transactionData.firstPayment),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "firstPayment", $$v)},expression:"data.transactionData.firstPayment"}}),(_vm.submittedForm && _vm.$v.data.transactionData.firstPayment.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.firstPayment.required)?_c('span',[_vm._v("Pembayaran pertama tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-check form-switch form-switch-md mb-3",attrs:{"dir":"ltr"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.transactionData.prorata),expression:"data.transactionData.prorata"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"customSwitchsizemd"},domProps:{"checked":Array.isArray(_vm.data.transactionData.prorata)?_vm._i(_vm.data.transactionData.prorata,null)>-1:(_vm.data.transactionData.prorata)},on:{"change":function($event){var $$a=_vm.data.transactionData.prorata,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.data.transactionData, "prorata", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.data.transactionData, "prorata", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.data.transactionData, "prorata", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"customSwitchsizemd"}},[_vm._v("Prorata")])]),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Biaya Bulanan","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.recurringAmount.$error,
                    },attrs:{"disabled":"","type":"number","for":"text"},model:{value:(_vm.data.transactionData.recurringAmount),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "recurringAmount", $$v)},expression:"data.transactionData.recurringAmount"}}),(_vm.submittedForm && _vm.$v.data.transactionData.recurringAmount.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.recurringAmount.required)?_c('span',[_vm._v("Biaya bulanan tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Tanggal Invoice","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.invoiceDate.$error,
                    },attrs:{"for":"text","type":"date"},model:{value:(_vm.data.transactionData.invoiceDate),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "invoiceDate", $$v)},expression:"data.transactionData.invoiceDate"}}),(_vm.submittedForm && _vm.$v.data.transactionData.invoiceDate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.invoiceDate.required)?_c('span',[_vm._v("Tanggal invoice tidak boleh kosong!.")]):_vm._e()]):_vm._e()],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.notes),expression:"data.notes"}],staticClass:"form-control",attrs:{"maxlength":225,"rows":"3","placeholder":"Catatan...."},domProps:{"value":(_vm.data.notes)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "notes", $event.target.value)}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }