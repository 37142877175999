<script>
import axios from 'axios';
// // import Switches from "vue-switches";
// import Swal from 'sweetalert2';
import Swal from 'sweetalert2';

export default {
  props: [ ],
  components: {
  },
  mounted () {

  },
  data() {
    return {
      data: {
        checkList: {
          laserConnection: false,
          internetConnection: false,
          customerConfirmation: false
        },
        ticketId: '',
        ticketNo: 'memuat data...'
      }
    }
  },
  methods: { 
    show (id) {
      // console.log('idnya', id)
      this.getData(id)
      this.$refs.modal.show();
    },
    async getData (id) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket?ticketId=' + id,).then(async response => {
        this.data = response.data
      });
    },
    resetModal () {
      this.data = {
        checkList: {},
        ticketId: '',
        ticketNo: ''
      }
    },
    async patchData () {
      // console.log('datanya', this.data.checkList)
      await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.data.ticketId, {checkList: this.data.checkList})
        // eslint-disable-next-line no-unused-vars
          .then(async response => {
            // console.log('resp', response)
            this.$emit('loadOptionDataParent')
            Swal.fire("Sukses!", "Data sukses disimpan!", "success");
            this.$refs['modal'].hide()
          }).catch(error=>{
            // console.error(error)
            Swal.fire("Gagal!", error.message, "error");
          })
    }
  },
  watch: {
    
  }
};
</script>
<template>
  <b-modal id="modal" ref="modal" size="m" centered :title="data.ticketNo" @hide="resetModal">
    <div class="row mb-2">
      <div class="col-5 ">
        Koneksi Laser
      </div>
      <div class="col-4">
        <div class="form-check form-check-right mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            id="formCheckRight1"
            v-model="data.checkList.laserConnection"
          />
          <label class="form-check-label" for="formCheckRight1">
            :
          </label>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-5 ">
        Koneksi Internet
      </div>
      <div class="col-4">
        <div class="form-check form-check-right mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            id="formCheckRight1"
            v-model="data.checkList.internetConnection"
          />
          <label class="form-check-label" for="formCheckRight1">
            :
          </label>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-5 ">
        Konfirmasi Ke Pelanggan
      </div>
      <div class="col-4">
        <div class="form-check form-check-right mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            id="formCheckRight1"
            v-model="data.checkList.customerConfirmation"
          />
          <label class="form-check-label" for="formCheckRight1">
            :
          </label>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-3 mt-1">
        Signal
      </div>
      <div v-if="signal.status!='-'" :class="{
                                        'text-success col-8': signal.status == 'Very good',
                                        'text-warning col-8': signal.status == 'Warning',
                                        'text-danger col-8': signal.status == 'Critical'
                                    }">
       {{ signal.text }}
      </div>
      <div v-else class='text-danger col-8'>
       Not Connected
      </div>
    </div> -->
    <template #modal-footer>
        <b-button  variant="primary" @click="patchData()">
          Simpan
        </b-button>
      </template>
  </b-modal>
</template>
  