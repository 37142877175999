var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"modal","centered":"","title":"Form Tambah Produk","title-class":"font-18","size":"lg"},on:{"hide":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Simpan ")])]},proxy:true}])},[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Produk","label-for":"text"}},[_c('b-form-select',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.productData.products.$error,
                    },staticStyle:{"width":"100%","height":"40px"},attrs:{"for":"text","value-field":"value","text-field":"text","options":_vm.listProduct},model:{value:(_vm.choice.product.value),callback:function ($$v) {_vm.$set(_vm.choice.product, "value", $$v)},expression:"choice.product.value"}}),(_vm.submittedForm && _vm.$v.data.productData.products.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.productData.products.required)?_c('span',[_vm._v("Produk tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Harga","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.price.$error,
                    },attrs:{"for":"text","type":"number","disabled":""},model:{value:(_vm.data.transactionData.price),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "price", $$v)},expression:"data.transactionData.price"}}),(_vm.submittedForm && _vm.$v.data.transactionData.price.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.price.required)?_c('span',[_vm._v("Harga tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Biaya Instalasi","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.cost.$error,
                    },attrs:{"for":"text","type":"number","disabled":""},model:{value:(_vm.data.transactionData.cost),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "cost", $$v)},expression:"data.transactionData.cost"}}),(_vm.submittedForm && _vm.$v.data.transactionData.cost.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.cost.required)?_c('span',[_vm._v("Biaya instalasi tidak boleh kosong!.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Status","label-for":"text"}},[_c('b-form-select',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.productData.productStatus.status.$error,
                    },staticStyle:{"width":"100%","height":"40px"},attrs:{"for":"text","options":_vm.statusList},model:{value:(_vm.data.productData.productStatus.status),callback:function ($$v) {_vm.$set(_vm.data.productData.productStatus, "status", $$v)},expression:"data.productData.productStatus.status"}}),(_vm.submittedForm && _vm.$v.data.productData.productStatus.status.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.productData.productStatus.status.required)?_c('span',[_vm._v("Status tidak boleh kosong!")]):_vm._e()]):_vm._e()],1)],1),_c('div',{staticClass:"col"},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Pembayaran Pertama","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.firstPayment.$error,
                    },attrs:{"for":"text","type":"number","disabled":""},model:{value:(_vm.data.transactionData.firstPayment),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "firstPayment", $$v)},expression:"data.transactionData.firstPayment"}}),(_vm.submittedForm && _vm.$v.data.transactionData.firstPayment.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.firstPayment.required)?_c('span',[_vm._v("Pembayaran pertama tidak boleh kosong!")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"example text","label":"Biaya Bulanan","label-for":"text"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submittedForm && _vm.$v.data.transactionData.recurringAmount.$error,
                    },attrs:{"disabled":"","type":"number","for":"text"},model:{value:(_vm.data.transactionData.recurringAmount),callback:function ($$v) {_vm.$set(_vm.data.transactionData, "recurringAmount", $$v)},expression:"data.transactionData.recurringAmount"}}),(_vm.submittedForm && _vm.$v.data.transactionData.recurringAmount.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.data.transactionData.recurringAmount.required)?_c('span',[_vm._v("Biaya bulanan tidak boleh kosong!")]):_vm._e()]):_vm._e()],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }