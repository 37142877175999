<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import appConfig from "@/app.config";
import modalInvoices from "@/components/modal-invoice";
import modalDetailProduct from "@/components/modal-detail-product";
import modalAddProduct from "@/components/modal-add-product";
import modalEditProduct from "@/components/modal-edit-product";
import modalChecklistTicket from "@/components/modal-checklist-ticket";
import modalStatusTicket from "@/components/modal-status-ticket";
import Swal from 'sweetalert2';
/**
 * Profile component
 */
export default {
    components: {Layout, PageHeader, modalInvoices, modalDetailProduct, modalAddProduct, modalEditProduct, modalChecklistTicket, modalStatusTicket},
     page: {
    title: "Customer Page",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
    mounted(){
        // console.log(this.$route.params.id)
        this.loadOptionData()
    },
    methods: {
        async loadOptionData(){
            const custId = this.$route.params.id
            await axios.get(process.env.VUE_APP_URL_FEATHERS+ 'customer-data?customerId=' + custId)
                .then(response => {
                    this.profileData = response.data
                    //  console.log('datanya', this.profileData.noHandphone)
                     this.profileData.noHandphone = this.profileData.noHandphone.slice(0,4)+ this.profileData.noHandphone.slice(2).replace(/.(?=....)/g,'*')
                     this.profileData.email = this.profileData.email.slice(0,4)+ this.profileData.email.slice(2).replace(/.(?=.............)/g,'*')
                })
            this.dataProducts = await axios.get(process.env.VUE_APP_URL_FEATHERS+ 'costumer-products?customerId=' + custId)
                .then(response => {
                    this.totalRows = response.data.length;
                    return response.data
                })
            this.dataInvoices = await axios.get(process.env.VUE_APP_URL_FEATHERS+ 'costumer-invoices?customerData=' + custId)
                .then(response => {
                    this.totalRowsInvoice = response.data.length;
                   
                    return response.data 
                })
            this.dataTicket = await axios.get(process.env.VUE_APP_URL_FEATHERS+ 'ticket?customerId=' + custId)
                .then(response => {
                    this.totalRowsTicket = response.data.length;
                    return response.data 
                })
            this.messageActive = await axios.get(process.env.VUE_APP_URL_FEATHERS+ 'ticket?countTicketActive=' + custId)
                .then(response => {
                    return response.data 
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFilteredInvoices(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsInvoice = filteredItems.length;
            this.currentPageInvoice = 1;
        },
        onFilteredTicket(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsTicket = filteredItems.length;
            this.currentPageTicket = 1;
        },
        onRowClicked(item){
            // console.log(item.id)
            this.$refs.modal.showModal(item.id)
        },
        onRowClickedProduct(id){
            this.$refs.modalDetailProd.show(id);
            // console.log(item)
        },
        addProduct(){
            this.$refs.modalAddProd.show();
        },
        editProduct(id){
            this.$refs.modalEditProd.show(id);
        },
        editChecklistTicket(id, check){
            if (check != 'ticketProgressDone') {
                Swal.fire("Tidak bisa update checklist!", "Pastikan ticket sudah dalam stage pengerjaan selesai!", "error");
            } else {
                this.$refs.modalChecklistTicket.show(id);
            }
            
        },
        editStatusTicket(id, check){
            // console.log('ceh', check)
            if (check) {
                this.$refs.modalStatusTicket.show(id);
            } else {
                Swal.fire("Tidak bisa update status!", "Checklist belum lengkap!", "error");
            }
        }
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.dataProducts.length;
        },
        rowsInvoice() {
            return this.dataInvoices.length;
        },
        rowsTicket() {
            return this.dataTicket.length;
        },
    },  
    data() {
        return {
            title: "Profile",
            dataProducts: [],
            dataInvoices: [],
            dataTicket: [],
            items: [{
                    text: "Contacts",
                },
                {
                    text: "Profile",
                    active: true,
                },
            ],
            profileData: {
                noHandphone: '',
                noHandphoneAlternatif: '',
                address: '',
                name: '',
                clientGroup: '',
                email: ''
            },
            messageActive: {},
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            bordered:true,
            sortBy: "orderId",
            sortDesc: true,
            totalRowsInvoice: 1,
            currentPageInvoice: 1,
            perPageInvoice: 10,
            pageOptionsInvoice: [10, 25, 50, 100],
            filterInvoice: null,
            filterOnInvoice: [],
            sortByInvoice: "createdAt",
            sortDescInvoice: false,
            totalRowsTicket: 1,
            currentPageTicket: 1,
            perPageTicket: 10,
            pageOptionsTicket: [10, 25, 50, 100],
            filterTicket: null,
            filterOnTicket: [],
            sortByTicket: "createdAt",
            sortDescTicket: true,
            fields: [
                {
                    label: "Order ID",
                    key: "orderId",
                    sortable: true,
                },
                {
                    label: "Nama Paket",
                    key: "products_name",
                    sortable: true,
                    formatter: (value) => {
                        if(value.length <=18) {
                            return value.toUpperCase()
                        } else {
                            return (value.substring(0, 18) + '...').toUpperCase()
                        }
                    },
                },
                {
                    label: "Status",
                    key: "status",
                    sortable: true,
                },
                {
                    label: "Aktivasi",
                    key: "activation",
                    sortable: true,
                },
                {
                    label: "Harga",
                    key: "price",
                    sortable: true,
                    formatter: (value) => {
                        var formatter = new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR'
                        });
                        return formatter.format(value);
                    },
                },
                {
                    key: "createdAt",
                    label: "Tanggal Registrasi",
                    sortable: true,
                }
            ],
            fieldsInvoice: [
                {
                    label: "No Invoice",
                    key: "noInvoice",
                    sortable: true,
                },
                {
                    label: "Order ID",
                    key: "orderId",
                    sortable: true,
                },
                {
                    label: "Nama Paket",
                    key: "productName",
                    sortable: true,
                    formatter: (value) => {
                        if(value.length <=18) {
                            return value.toUpperCase()
                        } else {
                            return (value.substring(0, 18) + '...').toUpperCase()
                        }
                    },
                },
                {
                    label: "Periode",
                    key: "periode",
                    sortable: true,
                },
                {
                    label: "Status",
                    key: "status",
                    sortable: true,
                },
                {
                    label: "Harga",
                    key: "amount",
                    sortable: true,
                    formatter: (value) => {
                        var formatter = new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR'
                        });
                        return formatter.format(value);
                    },
                },
                {
                    label: "Harga + PPn",
                    key: "grossAmount",
                    sortable: true,
                    formatter: (value) => {
                        var formatter = new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR'
                        });
                        return formatter.format(value);
                    },
                },
                { key: 'actions', label: '' }
            ],
            fieldsTicket: [
                {
                    label: "No Ticket",
                    key: "noTicket",
                    sortable: true,
                },
                {
                    label: "Order ID",
                    key: "orderId",
                    sortable: true,
                },
                {
                    label: "Kategori",
                    key: "category",
                    sortable: true,
                },
                {
                    label: "Stage",
                    key: "stage",
                    sortable: true
                },
                {
                    label: "Status",
                    key: "status",
                    sortable: true,
                },
                {
                    label: "Assignment",
                    key: "assignment",
                    sortable: true
                },
                {
                    label: "Kendala",
                    key: "problems",
                    sortable: true,
                    formatter: (value) => {
                        if(value.length <=30) {
                            return value
                        } else {
                            return (value.substring(0, 30) + '...')
                        }
                    },
                },
                { key: 'checkValue', label: 'Checklist' },
                {
                    key: "createdAt",
                    label: "Tanggal Buat",
                    sortable: true,
                },
                {
                    key: "updatedAt",
                    label: "Tanggal Edit",
                    sortable: true,
                }
            ],
        };
    },
    
};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-4">
        <modalInvoices  @loadOptionDataParent="loadOptionData" ref="modal"></modalInvoices>
        <modalDetailProduct  ref="modalDetailProd"></modalDetailProduct>
        <modalAddProduct  @loadOptionDataParent="loadOptionData" ref="modalAddProd"></modalAddProduct>
        <modalEditProduct  @loadOptionDataParent="loadOptionData" ref="modalEditProd"></modalEditProduct>
        <modalChecklistTicket  @loadOptionDataParent="loadOptionData" ref="modalChecklistTicket"></modalChecklistTicket>
        <modalStatusTicket  @loadOptionDataParent="loadOptionData" ref="modalStatusTicket"></modalStatusTicket>
        <div class="col-xl-2">
            <div class="card h-100">
                <div class="card-body">
                    <div class="text-center">
                        <b-dropdown class="float-end" variant="white" right menu-class="dropdown-menu-end" toggle-class="font-size-16 text-body p-0">
                            <template v-slot:button-content>
                                <i class="uil uil-ellipsis-v"></i>
                            </template>
                            <a class="dropdown-item" href="#">Edit</a>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Remove</a>
                        </b-dropdown>
                        <div class="clearfix"></div>
                        <div>
                            <img src="@/assets/images/users/default-avatar.png" alt class="avatar-lg rounded-circle img-thumbnail" />
                        </div>
                        <h5 class="mt-3 mb-1">{{ profileData.name }}</h5>
                        <p class="text-muted">{{ profileData.clientGroup }}</p>

                        <!-- <div class="mt-4">
                            <button type="button" class="btn btn-light btn-sm">
                                <i class="uil uil-envelope-alt me-2"></i> Message
                            </button>
                        </div> -->
                    </div>

                    <hr class="my-4" />

                    <div class="text-muted">
                        <div class="table-responsive mt-4 mb-0">
                            <div>
                                <p class="mb-1">Alamat :</p>
                                <h5 class="font-size-16">{{ profileData.address }}</h5>
                            </div>
                            <div class="mt-4">
                                <p class="mb-1">No Handphone :</p>
                                <h5 class="font-size-16">{{ profileData.noHandphone }}</h5>
                            </div>
                            <div class="mt-4">
                                <p class="mb-1">E-mail :</p>
                                <h5 class="font-size-16">{{ profileData.email }}</h5>
                            </div>
                            <div class="mt-4">
                                <p class="mb-1">No Handphone Alternatif :</p>
                                <h5 class="font-size-16">{{ profileData.noHandphoneAlternatif }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-10">
            <div class="card mb-0">
                <b-alert v-if="messageActive.anyActive" class="mt-2" style="margin-left: 2%; margin-right: 2%;" variant="warning" show
                >{{ messageActive.message }}</b-alert
              >
                <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                    <b-tab active>
                        <template v-slot:title>
                            <i class="uil uil-shopping-basket font-size-20"></i>
                            <span class="d-none d-sm-block">Products</span>
                        </template>
                        <div class="card">
                            <div class="card-body">
                                <div class="row mt-4">
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                        v-model="perPage"
                                        size="sm"
                                        :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                    >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                    </div>
                                </div>
                                <!-- End search -->
                                </div>
                                <!-- Table -->
                                <div class="table-responsive mb-0">
                                <b-table
                                striped hover
                                head-variant="light"
                                    :items="dataProducts"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                                    tbody-tr-class="table"
                                >
                                    <template #cell(products_name)="row">
                                        {{ row.item.products_name }}
                                        <b-button @click="editProduct(row.item.id)" size="sm" variant="warning" class="fas fa-pen" style="margin-right: 2px;">
                                        </b-button>
                                        <b-button @click="onRowClickedProduct(row.item.id)" size="sm" variant="primary" class="fas fa-network-wired">
                                        </b-button>
                                    </template>
                                    <template #cell(status)="row">
                                        <span v-if="row.item.status=='pending'" class="badge bg-warning font-size-12 me-1"> Menunggu Aktivasi</span>
                                        <span v-if="row.item.status=='active'" class="badge bg-success font-size-12 me-1"> Aktif</span>
                                        <span v-if="row.item.status=='suspend'" class="badge bg-danger font-size-12 me-1"> Isolir</span>
                                        <span v-if="row.item.status=='terminated'" class="badge bg-dark font-size-12 me-1"> Berhenti Langganan</span>
                                    </template>
                                </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <b-button size="sm" style="width: 300px;" variant="primary" @click="addProduct()">
                                            Tambah Produk
                                        </b-button >
                                    </div>
                                    <div class="col">
                                        <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                        >
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                ></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-invoice font-size-20"></i>
                            <span class="d-none d-sm-block">Invoice</span>
                        </template>
                        <div class="card">
                            <div class="card-body">
                                <div class="row mt-4">
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                        v-model="perPageInvoice"
                                        size="sm"
                                        :options="pageOptionsInvoice"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                    >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                        v-model="filterInvoice"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                    </div>
                                </div>
                                <!-- End search -->
                                </div>
                                <!-- Table -->
                                <div class="mb-0">
                                <b-table
                                striped hover
                                head-variant="light"
                                    :items="dataInvoices"
                                    :fields="fieldsInvoice"
                                    responsive="sm"
                                    :per-page="perPageInvoice"
                                    :current-page="currentPageInvoice"
                                    :sort-by.sync="sortByInvoice"
                                    :sort-desc.sync="sortDescInvoice"
                                    :filter="filterInvoice"
                                    :filter-included-fields="filterOnInvoice"
                                    @filtered="onFilteredInvoices"
                                    tbody-tr-class="table"
                                    @row-clicked="onRowClicked"
                                >
                                    <template #cell(status)="row">
                                        <span v-if="row.item.status=='pending'" class="badge bg-warning font-size-12 me-1"> Belum Bayar</span>
                                        <span v-if="row.item.status=='settlement'" class="badge bg-success font-size-12 me-1"> Sudah Bayar</span>
                                        <span v-if="row.item.status=='cancel'" class="badge bg-secondary font-size-12 me-1"> Dibatalkan</span>
                                        <span v-if="row.item.status=='expire'" class="badge bg-danger font-size-12 me-1"> Kadaluarsa</span>
                                        <span v-if="row.item.status=='deny'" class="badge bg-danger font-size-12 me-1"> Ditolak</span>
                                    </template>
                                    <!-- <template #cell(actions)="row">
                                        <modalInvoices
                                        :orderIdprops='`${row.item.id}`'>

                                      </modalInvoices>
                                        <b-dropdown
                                            variant="light"
                                            class="chat-noti-dropdown"
                                            toggle-class="py-0"
                                        >
                                            <template v-slot:button-content>
                                                <i class="mdi mdi-dots-vertical"></i>
                                            </template>
                                            <b-dropdown-item v-if="row.item.status!='settlement'" @click="info(row)">Update Pembayaran</b-dropdown-item>
                                            <b-dropdown-item v-if="row.item.status=='settlement'" @click="info(row)">Cetak Pembayaran</b-dropdown-item>
                                        </b-dropdown>
                                        <b-dropdown variant="light" size="sm" class="me-1 mt-2">
                                            <template v-slot:button-content>
                                                <i class="mdi mdi-dots-vertical"></i>
                                            </template>
                                            <b-dropdown-item-button @click="info(row)">Update Invoice</b-dropdown-item-button>
                                            <b-dropdown-item-button>Cetak Invoice</b-dropdown-item-button>
                                        </b-dropdown>
                                    </template> -->
                                </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                        >
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination
                                                v-model="currentPageInvoice"
                                                :total-rows="rowsInvoice"
                                                :per-page="perPageInvoice"
                                                ></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>
                            <i class="uil uil-ticket font-size-20"></i>
                            <span class="d-none d-sm-block">Tickets</span>
                        </template>
                        <div class="card">
                            <div class="card-body">
                                <div class="row mt-4">
                                <div class="col-sm-12 col-md-6">
                                    <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                        v-model="perPageTicket"
                                        size="sm"
                                        :options="pageOptionsTicket"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                    >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                        v-model="filterTicket"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                    </div>
                                </div>
                                <!-- End search -->
                                </div>
                                <!-- Table -->
                                <div class="mb-0">
                                <b-table
                                striped hover
                                head-variant="light"
                                    :items="dataTicket"
                                    :fields="fieldsTicket"
                                    responsive="sm"
                                    :per-page="perPageTicket"
                                    :current-page="currentPageTicket"
                                    :filter="filterTicket"
                                    :filter-included-fields="filterOnTicket"
                                    @filtered="onFilteredTicket"
                                    tbody-tr-class="table"
                                >
                                    <template #cell(stage)="row">
                                        <span v-if="row.item.stage=='ticketSubmitted'" class="badge bg-danger font-size-12 me-1"> Ticket Dibuat</span>
                                        <span v-if="row.item.stage=='ticketOnProgress'" class="badge bg-warning font-size-12 me-1"> Dalam Pengerjaan</span>
                                        <span v-if="row.item.stage=='ticketProgressDone'" class="badge bg-secondary font-size-12 me-1"> Pengerjaan Selesai</span>
                                        <span v-if="row.item.stage=='ticketDone'" class="badge bg-success font-size-12 me-1"> Ticket Selesai</span>
                                        <!-- <b-button @click="editStatusTicket(row.item.id)" v-if="row.item.stage=='ticketSubmitted'"  size="sm" variant="danger"> Ticket Dibuat
                                        </b-button>
                                        <b-button @click="editStatusTicket(row.item.id)" v-if="row.item.stage=='ticketOnProgress'"  size="sm" variant="warning"> Dalam Pengerjaan
                                        </b-button>
                                        <b-button @click="editStatusTicket(row.item.id)" v-if="row.item.stage=='ticketProgressDone'"  size="sm" variant="secondary"> Pengerjaan Selesai
                                        </b-button>
                                        <b-button @click="editStatusTicket(row.item.id)" v-if="row.item.stage=='ticketDone'"  size="sm" variant="success"> Ticket Selesai
                                        </b-button> -->
                                    </template>
                                    <template #cell(status)="row">
                                        <!-- <span v-if="row.item.status=='open'" class="badge bg-warning font-size-12 me-1"> Open</span>
                                        <span v-if="row.item.status=='closed'" class="badge bg-success font-size-12 me-1"> Closed</span> -->
                                        <b-button @click="editStatusTicket(row.item.id, row.item.checkValue)" v-if="row.item.status=='open'"  size="sm" variant="warning" :disabled= "row.item.stage != 'ticketProgressDone'"> Open
                                        </b-button>
                                        <b-button :disabled=true v-if="row.item.status=='closed'"  size="sm" variant="success"> Closed
                                        </b-button>
                                    </template>
                                    <template #cell(checkValue)="row">
                                        <b-button v-if="row.item.checkValue"  size="sm" variant="light" :disabled="true" class="fas fa-check" style=" color:rgb(0, 185, 0)">
                                        </b-button>
                                        <b-button @click="editChecklistTicket(row.item.id, row.item.stage)" v-if="!row.item.checkValue"  size="sm" variant="light" class="fas fa-times" style="color:red">
                                        </b-button>
                                        <!-- <i v-if="row.item.checkValue" class="fas fa-check" style="color:rgb(0, 185, 0)"></i>
                                        <i v-if="!row.item.checkValue" class="fas fa-times" style="color:red"></i> -->
                                    </template>
                                </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                        >
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination
                                                v-model="currentPageTicket"
                                                :total-rows="rowsTicket"
                                                :per-page="perPageTicket"
                                                ></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
                <!-- Nav tabs -->
                <!-- Tab content -->
            </div>
        </div>
    </div>
    <!-- end row -->
</Layout>
</template>
